<template>
  <div class="main-container">
    <div class="header-with-button">
      <h1 class="main-heading">{{ $t("Passive Event") }}</h1>
      <Button
        v-if="$route.query.id"
        type="success"
        :onClick="openConfirm"
        text="Submit"
        :loading="false"
        size="md"
      />
      <Button
        v-else
        type="success"
        :onClick="submit"
        text="Submit"
        :loading="false"
        size="md"
      />
    </div>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t("PROPERTIES") }}</div>
          <div class="form-container">
            <select v-model="selectedLanguage">
              <option
                v-for="language in languages"
                v-bind:key="language.code"
                :value="language.code"
              >
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.MODIFIER_TYPE`) }}</p>
            <select v-model.number="currentEvent.passive_event_type">
              <option
                v-for="type in eventConstants.PASSIVE_EVENT_TYPES"
                v-bind:key="type.value"
                :value="type.value"
              >
                {{ type.text }}
              </option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Event Threshold" }}</p>
            <input
              type="number"
              v-model.number="currentEvent.threshold"
              min="0"
            />
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.START_DATE`) }}</p>
            <DatePicker
              v-model.number="currentEvent.start_date"
              mode="dateTime"
              :min-date="Date.now()"
              :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input id="start_date" :value="inputValue" v-on="inputEvents" />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.END_DATE`) }}</p>
            <DatePicker
              v-model.number="currentEvent.end_date"
              mode="dateTime"
              :min-date="Date.now()"
              :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input id="end_date" :value="inputValue" v-on="inputEvents" />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ "Popup Asset" }}</p>
            <multiselect
              v-model="currentEvent.event_asset"
              :options="assetOptions"
              :multiple="false"
            />
          </div>
        </div>
        <div class="col">
          <div class="header-with-button">
            <div class="col-title">{{ "Info Fields" }}</div>
            <div class="flex">
              <Button
                :loading="false"
                text="Get Defaults"
                size="sm"
                :onClick="() => getDefaultInfoFields()"
                type="info"
              />
            </div>
          </div>
          <div class="form-container">
            <p>Icon</p>
            <select v-model.number="currentEvent.event_icon">
              <option
                v-for="type in eventConstants.INFO_FIELD_ICONS"
                v-bind:key="type.value"
                :value="type.value"
              >
                {{ $t(type.text) }}
              </option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Popup Header" }}</p>
            <input
              v-model="currentEvent.localizables.popup_header[selectedLanguage]"
            />
          </div>
          <div class="form-container">
            <p>{{ "Popup Subheader" }}</p>
            <input
              v-model="
                currentEvent.localizables.popup_subheader[selectedLanguage]
              "
            />
          </div>
          <div class="form-container">
            <p>{{ "Popup Info" }}</p>
            <input
              v-model="currentEvent.localizables.popup_info[selectedLanguage]"
            />
          </div>
          <push-noification-schedule :notifications="currentEvent.push_notifications" :setNotifications="(data) => { currentEvent.push_notifications = data }" />
        </div>
      </div>
      <ConfirmPopup
        :popup-open="popupOpen"
        :text="popupText"
        @popup-close="popupOpen = false"
        :type="popupType"
        :changes="changes"
        @confirm="submit"
      />
    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  onMounted,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  ref,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import dispatchMap from "@/constants/dispatchMap";

import eventConstants from "@/constants/eventConstants";
import unitConstants from "@/constants/unitConstants";
import languageConstants from "@/constants/languagesTemp";
import eventSchema from "@/schemas/event";
import "v-calendar/dist/style.css";
import { io } from "socket.io-client";

import PushNoificationSchedule from '../../components/PushNoificationSchedule.vue';
import ConfirmPopup from "../../components/common/ConfirmPopup.vue";
import Button from "../../components/common/Button.vue";
import changeDetector from "@/util/changeDetector";

export default {
  name: "PassiveEventAdd",
  components: {
    ConfirmPopup,
    Button,
    PushNoificationSchedule
  },
  setup() {
    const store = useStore();
    const usedPages = ["events"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const selectedLanguage = ref("en");
    const languages = languageConstants.languages;
    const popupOpen = ref(false);
    const popupTexts = [
      "This page is edited by another user. Please refresh before making changes.",
      "Your changes: ",
    ];
    const popupText = ref(popupTexts[0]);
    const popupType = ref("refresh");
    const changes = ref([]);
    window.firstEvent = null;
    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );
    const events = computed(() => store.getters["events/getEvents"]);
    const chests = computed(() => store.getters["chests/getChests"]);
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined,
        },
        ...store.getters["assets/getAssets"].files,
      ];
    });
    const assetOptions = computed(() => {
      let assetArray = []
      assetList.value.forEach(asset => {
        if(asset.doc_ref === undefined){
          assetArray.push("DEFAULT BANNER")
        }
        else{
          assetArray.push(asset.doc_ref)
        }
      })
      return assetArray
    });
    const currentEvent = reactive({ ...eventSchema.getPassiveEventSchema() });
    const route = useRoute();

    onMounted(() => {
      if (route.query.id && events.value.length > 0) {
        const editingEvent = JSON.parse(
          JSON.stringify(events.value.find((x) => x.id === route.query.id))
        );
        Object.keys(editingEvent).forEach((key) => {
          currentEvent[key] = editingEvent[key];
        });
        window.firstEvent = JSON.parse(JSON.stringify(currentEvent));
      }
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["events/getEvents"],
      (events) => {
        if (route.query.id && events.length > 0) {
          const editingEvent = JSON.parse(
            JSON.stringify(events.find((x) => x.id === route.query.id))
          );
          Object.keys(editingEvent).forEach((key) => {
            currentEvent[key] = editingEvent[key];
          });
          window.firstEvent = JSON.parse(JSON.stringify(currentEvent));
        }
      }
    );

    const submit = () => {
      if (route.query.id) {
        store
          .dispatch("events/updateEvent", {
            id: route.query.id,
            updateBody: currentEvent,
          })
          .then(() => {
            socket.emit("update", {
              socketId: socket.id,
              itemId: currentEvent.id,
              page: "Event Passive",
            });
          });
      } else {
        store.dispatch("events/addEvent", currentEvent).then(() => {
          socket.emit("add", {
            socketId: socket.id,
            itemId: "Event Passive",
            page: "Event Passive",
            data: currentEvent,
          });
        });
      }
    };

    socket.on("updated", (data) => {
      if (
        data.socketId != socket.id &&
        data.itemId === currentEvent.id &&
        data.page === "Event Passive" &&
        data.data
      ) {
        popupOpen.value = true;
        popupType.value = "refresh";
        popupText.value = popupTexts[0];
        changes.value = changeDetector.detect(
          currentEvent,
          data.data,
          "eventPassive"
        );
      }
    });

    const getDefaultInfoFields = () => {
      currentEvent.localizables = eventSchema.getPassiveEventTexts(
        currentEvent.passive_event_type
      );
    };

    const openConfirm = () => {
      popupType.value = "confirm";
      popupOpen.value = true;
      popupText.value = popupTexts[1];
      changes.value = changeDetector.detect(
        window.firstEvent,
        currentEvent,
        "eventPassive"
      );
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";

    return {
      chests,
      eventConstants,
      unitConstants,
      currentEvent,
      submit,
      env,
      assetList,
      assetOptions,
      popupOpen,
      popupText,
      popupType,
      selectedLanguage,
      languages,
      getDefaultInfoFields,
      changes,
      openConfirm,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: start;
}
.second-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}
.third-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  text-align: start;
}

.leaderboard-container {
  display: inline-flex;
  padding: 5px 20px;
  align-items: center;
  justify-content: center;
  height: auto;
}

.leaderboard-container span {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input,
textarea,
select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.5rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

.leaderboard-cell.alternate {
  background-color: lightgrey;
}
.leaderboard-cell {
  margin: 10px 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

.reward-cell * {
  margin: 5px 10px;
}

.reward-cell {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}
label.field-info {
  font-size: 0.7rem;
}

.info-field-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-bottom: 20px;
}

.header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex {
  display: flex;
}

.margin {
  margin: 10px;
}
</style>